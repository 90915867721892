import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Space, Button, Typography, Table, message, Tooltip } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getSalesOrderList } from '../ducks/actions'
import { exportSoReport } from '../ducks/services'
import { Popup } from 'Atoms/Popup';
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { CheckCircleTwoTone } from '@ant-design/icons';

function comma(nStr) {
  nStr += '';
  let x = nStr.split('.');
  let x1 = x[0];
  let x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

const { Title, Text } = Typography;

const filters = [
  {
    label: "Invoice Report",
    value: "invoice"
  },
  {
    label: "Sales Orders Report",
    value: "bill"
  },
]

export default (props) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reporting.salesOrders);
  const meta = useSelector((state) => state.reporting.salesOrdersOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [searchVal, setSearchVal] = useState(null);
  const [visible, setVisible] = useState(false);
  const [rec, setRecord] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [tenderTypes, setTenderTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selected, setSelected] = useState(filters[0].value);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const globalListQuery = useSelector((state) => state.global.listQueryItems);
  // const [activeTab, setActiveTab] = useState("1");
  const [customActiveTab, setCustomActiveTab]= useState("1");

  const colName = [
    {
      title: 'Outlet',
      dataIndex: 'location_code',
      key: 'location_code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Order Type',
      dataIndex: 'order_type_desc',
      key: 'order_type_desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Business Date',
      dataIndex: 'report_date',
      key: 'report_date',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Bill No.',
      dataIndex: 'order_no',
      key: 'order_no',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Collection No.',
      dataIndex: 'collection_no',
      key: 'collection_no',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Gross Amount',
      dataIndex: 'sub_total_excluded_tax',
      key: 'sub_total_excluded_tax',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`
    },
    {
      title: 'Discount',
      dataIndex: 'discount_amount',
      key: 'discount_amount',
      className: 'enable-click',
      align: 'right',
      sorter: true
    },
    {
      title: 'Net Sales',
      dataIndex: 'net_sales',
      key: 'net_sales',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`

    },
    {
      title: 'Tax Amount',
      dataIndex: 'tax_amount',
      key: 'tax_amount',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`

    },
    {
      title: 'Charges',
      dataIndex: 'delivery_charge',
      key: 'delivery_charge',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`
    },
    {
      title: 'Voucher',
      dataIndex: 'voucher_amount',
      key: 'voucher_amount',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`
    },
    {
      title: 'Adjustment Amount',
      dataIndex: 'adjustment_amount',
      key: 'adjustment_amount',
      className: 'enable-click',
      align: 'right',
      sorter: true
    },
    {
      title: 'Net Total',
      dataIndex: 'amount',
      key: 'amount',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`

    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Paid" ? "c-success" : "c-danger"}>
            {text === "Paid" ? "Completed" : text}
          </span>
          {record?.inventory_netsuite_synced === "1" && record?.tender_netsuite_synced === "0" && <Tooltip title="COGS Synced to Netsuite"><CheckCircleTwoTone twoToneColor="#c3423f" className="ml-1" /></Tooltip>}
          {record?.inventory_netsuite_synced === "0" && record?.tender_netsuite_synced === "1" && <Tooltip title="Tender Synced to Netsuite"><CheckCircleTwoTone twoToneColor="#1677ff" className="ml-1" /></Tooltip>}
          {record?.inventory_netsuite_synced === "1" && record?.tender_netsuite_synced === "1" && <Tooltip title="Synced to Netsuite"><CheckCircleTwoTone twoToneColor="#52c41a" className="ml-1" /></Tooltip>}
        </div>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        <ActionButton
          title="Sales Order"
          btnAction1={() => {
            setVisible(true);
            setRecord(record);
            setCustomActiveTab("1");
          }}
          recordId={record.id}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const colName2 = [
    {
      title: 'QTY',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      className: 'valign-top',
      width: 30
    },
    {
      title: 'ITEM',
      dataIndex: 'name',
      key: 'name',
      className: 'valign-top',
      render: (text, record) => <>
        {record.quantity > 1 ? `${text} @${comma(displayTax(rec) ? record.price : record.price_excluded_tax)}` : text}
        <br/>
        {record.parts.map((x, ind) => {
          return (
            <Row justify={'space-between'} align={'middle'} key={ind}>
              <Space size={15}>
                <span>{x.quantity || record.quantity}</span>
                <span>{x.name}</span>
              </Space>
            </Row>
          )
        })}
      </>
    },
    {
      title: meta?.currency_symbol,
      dataIndex: 'amount_excluded_parts',
      key: 'amount_excluded_parts',
      align: 'right',
      className: 'valign-top',
      width: 105,
      render: (text, record) => {
        const price = comma(displayTax(rec) ? text : record?.amount_excluded_parts_excluded_tax);
        return (
          <>
            <span className={meta?.receipt_hide_zero_amount && parseFloat(price) <= 0 ? "invisible" : ""}>{price}</span>
            <br />
            {record.parts.map((x, ind) => {
              return (
                <div className='text-right' key={ind}>
                  {record.name?.length > 39 && <br />}
                  {displayTax(rec)
                    ? parseFloat(x.amount)
                      ? <span>{comma(x.amount)}</span>
                      : <span className='invisible'>-</span>
                    : parseFloat(x.amount_excluded_tax)
                      ? <span>{comma(x.amount_excluded_tax)}</span>
                      : <span className='invisible'>-</span>
                  }
                </div>
              )
            })}
          </>
        )
      }
    },
  ];

  const inventoryColName = [
    {
      title: 'Product Code',
      dataIndex: 'product_code',
      key: 'product_code',
      align: 'center',
      className: 'valign-top',
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_code',
      align: 'center',
      className: 'valign-top',
    },
    {
      title: 'UOM Code',
      dataIndex: 'uom_code',
      key: 'uom_code',
      align: 'center',
      className: 'valign-top',
    },
    {
      title: 'Source Name',
      dataIndex: 'source_name',
      key: 'source_name',
      align: 'center',
      className: 'valign-top',
    },
    {
      title: 'Unit Cost',
      dataIndex: 'unit_cost',
      key: 'unit_cost',
      align: 'right',
      className: 'valign-top',
    },
    {
      title: 'QTY',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      className: 'valign-top',
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
      align: 'right',
      className: 'valign-top',
    },
    {
      title: 'Netsuite Synced?',
      dataIndex: 'netsuite_synced',
      key: 'netsuite_synced',
      className: 'valign-top',
      render: (text) => text === "1" ? <Tooltip title="Synced to Netsuite"><CheckCircleTwoTone twoToneColor="#52c41a" className="ml-1" /></Tooltip> : "No"
    },
  ]

  const displayTax = (salesOrder) => {
    return (orders?.tax_display_inclusive || (salesOrder.tax_inclusive == "1" && parseFloat(salesOrder.discount_amount) > 0));
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: 
      <Space direction='vertical' size={20} className='w-100'>
        <Title level={4} className='mb-0'>
          {rec?.order_no}
        </Title>
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Title level={5} className='mb-0'>
              Status: <span className={`${rec?.status === "Paid" ? "c-success" : "c-danger"}`}>{rec?.status === "Paid" ? "Completed" : rec?.status}</span>
            </Title>
          </Col>
          {(rec?.reasons && rec?.reasons.length) ?
            <Col span={24}>
              <div>Reasons:</div>
              <ul>
                { rec?.reasons?.map(item => <li key={`${item.id} ${item.desc}`}>{item.desc}</li>) }
              </ul>
            </Col>
            : ''
          }
        </Row>

        {/* Pop Up Tabs */}
        <div className="popup-tabs mb-5">
          <div className={`tab-item ${customActiveTab === "1" ? 'active' : ''}`} onClick={() => setCustomActiveTab("1")}>Bill</div>
          {
            allowed([AllRoles.REPORTING.SALES], 'read') &&
            <div className={`tab-item ${customActiveTab === "2" ? 'active' : ''}`} onClick={() => setCustomActiveTab("2")}>Inventory</div>
          }
        </div>

        {customActiveTab === "1" &&
          <Row gutter={[10, 10]} justify={'center'}>
            {rec?.organization?.receipt_hide_logo === "0" &&
              <Col span={24} className='text-center'>
                <img src={rec?.organization?.image_url} alt="Logo" width='100px' />
              </Col>
            }
            <Col span={24} className='text-center'>
              {rec?.organization?.name}
              <br />
              {rec?.organization?.tax_label}
            </Col>
            <Col span={24} className='text-center'>
              {rec?.location?.address1}
              <br />
              {rec?.location?.address2}
              <br />
              {rec?.location?.address3}
            </Col>
            <Col span={22} className='text-center'>
              <hr style={{borderStyle: "dashed"}} />
            </Col>
            <Col span={22} className=''>
              INVOICE No: {rec?.order_no}
              <br />
              <div style={{display: "flex", justifyContent: "space-between"}}><span>Date: {rec?.date}</span> <span>{rec?.time}</span></div>
              Cashier: {rec?.created_by_name}
              <br />
              PRN ON: {rec?.paid_at_str}
            </Col>
            <Col span={22} className='text-center'>
              <hr style={{borderStyle: "dashed"}} />
            </Col>
            <Col span={24} className='text-center'>
              *** {rec?.order_type_desc} *** {rec?.collection_no && `- ${rec?.collection_no}`}
            </Col>
            <Col span={24}>
              <Table
                className='sales-table'
                dataSource={rec?.sales_order_items?.map((x, ind) => ({ ...x, key: `Child Item ${x.id} - ${ind}` }))}
                columns={colName2}
                pagination={false}
                summary={() => {
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} className="valign-top text-center">
                          <Text>{rec?.items_quantity}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} className="valign-top">
                          <Text>SubTotal</Text>
                          <br />
                          {
                            parseFloat(rec?.discount_amount) > 0 &&
                            <>
                              <Text>Discount {`(${rec?.discount_desc})`}</Text>
                              <br />
                              {
                                rec?.discount_remark &&
                                <>
                                  <Text>{rec?.discount_remark}</Text>
                                  <br />
                                </>
                              }
                            </>
                          }
                          {
                            (!displayTax(rec) || rec?.tax_inclusive === "0") &&
                            rec?.tax_condition_items?.map((x, ind) => {
                              return (<React.Fragment key={ind}>
                                <Text>{x.desc}</Text>
                                <br />
                              </React.Fragment>)
                            })
                          }
                          {
                            parseFloat(rec?.delivery_charge) > 0 &&
                            <>
                              <Text>{rec?.delivery_charge_label}</Text>
                              <br />
                            </>
                          }
                          {
                            rec?.vouchers.length > 0 && 
                            rec?.vouchers.map(item => <>
                              <Text>{item.voucher_type_desc}</Text>
                              <br />
                              <Text>(Ref No: {item.voucher_code})</Text>
                              <br />
                            </>)
                          }
                          {
                            parseFloat(rec?.adjustment_amount) !== 0 &&
                            <>
                              <Text>Rounding Adj</Text>
                              <br />
                            </>
                          }
                          <Text>Net Total</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2} className="valign-top text-right">
                          <Text>
                            {
                              displayTax(rec) ? rec?.sub_total : rec?.sub_total_excluded_tax
                            }
                          </Text>
                          <br />
                          {
                            parseFloat(rec?.discount_amount) > 0 &&
                            <>
                              <Text>-{comma(rec?.discount_amount)}</Text>
                              <br />
                              {
                                rec?.discount_remark &&
                                <>
                                  <Text/>
                                  <br />
                                </>
                              }
                            </>
                          }
                          {
                            (!displayTax(rec) || rec?.tax_inclusive === "0") &&
                            rec?.tax_condition_items?.map((x, ind) => {
                              return (<React.Fragment key={ind}>
                                <Text>{comma(x.total_tax_amount)}</Text>
                                <br />
                              </React.Fragment>)
                            })
                          }
                          {
                            parseFloat(rec?.delivery_charge) > 0 &&
                            <>
                              <Text>{comma(rec?.delivery_charge)}</Text>
                              <br />
                            </>
                          }
                          {
                            rec?.vouchers.length > 0 && 
                            rec?.vouchers.map(item => <>
                              <Text>-{comma(item.amount)}</Text>
                              <br />
                              <br />
                            </>)
                          }
                          {
                            parseFloat(rec?.adjustment_amount) !== 0 &&
                            <>
                              <Text>{comma(rec?.adjustment_amount)}</Text>
                              <br />
                            </>
                          }
                          <Text>{comma(rec?.amount)}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={2} className="valign-top">
                          <Space direction='vertical' size={5}>
                            {
                              rec?.payments?.map((x, ind) => (
                                <Text key={ind}>{x.payment_method_desc}</Text>
                              ))
                            }
                            {
                              parseFloat(rec?.change_amount) > 0 &&
                              <Text>CHANGE {rec?.currency_code}</Text>
                            }
                          </Space>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0} colSpan={1} className="text-right">
                          <Space direction='vertical' size={5}>
                            {
                              rec?.payments?.map((x, ind) => (
                                <Text key={ind}>{comma(x.amount)}</Text>
                              ))
                            }
                            {
                              parseFloat(rec?.change_amount) > 0 &&
                              <Text>{comma(rec?.change_amount)}</Text>
                            }
                          </Space>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={2} className="valign-top">
                          <Row justify={'space-between'} align={'middle'}>
                            <Col>
                              <Text>Tax Summary</Text>
                              {
                                rec?.tax_condition_items?.map((x, ind) => {
                                  return (
                                    <Fragment key={ind}>
                                      <br />
                                      <Text>{x.desc}</Text>
                                    </Fragment>
                                  )
                                })
                              }
                            </Col>
                            <Col>
                              <Text>Taxable</Text>
                              {
                                rec?.tax_condition_items?.map((x, ind) => {
                                  return (
                                    <Fragment key={ind}>
                                      <br />
                                      <Text>{comma(rec?.net_sales)}</Text>
                                    </Fragment>
                                  )
                                })
                              }
                            </Col>
                          </Row>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2} className="valign-top text-right">
                          <Text>Tax</Text>
                          {
                            rec?.tax_condition_items?.map((x, ind) => {
                              return (
                                <Fragment key={ind}>
                                  <br/>
                                  <Text>{comma(x?.total_tax_amount)}</Text>
                                </Fragment>
                              )
                            })
                          }
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={4} className="valign-top">
                          <Title level={5} className='text-center mb-0'>
                            {rec?.organization?.receipt_footer_desc?.map((x, ind) => (<div key={ind} className={x === "" ? "mt-1" : ""}>{x}</div>))}
                          </Title>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      {rec?.organization?.qr_url &&
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={4} className="valign-top text-center">
                            <img src={rec?.organization?.qr_url} alt="Logo" width='100px' />
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      }
                    </>
                  )
                }}
              />
            </Col>
          </Row>
        }

        {customActiveTab === "2" && 
          <div className='inventory-entries-table'>
            <Table columns={inventoryColName} dataSource={rec?.inventory_entries?.map(x => ({ ...x, key: x.id }))} pagination={false} />
          </div>
        }
      
        <Row gutter={10} justify={'center'}>
          <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
            setVisible(false);
            setRecord();
          }}>Close</Button></Col>
        </Row>
      </Space>
    ,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setRecord();
    },
  };

  const btnList = [
    {
      text: 'Export Report',
      classes: 'green-btn',
      action: () => exportReport(),
    }
  ];

  useEffect(() => {
    if(!globalListQuery.salesListQuery) {
      dispatch(getSalesOrderList(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('salesListQuery', 1, limit, '', '', null, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.salesListQuery
      dispatch(getSalesOrderList(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = [];
        meta.locations?.map((x, ind) => {
          temp.push({
            label: `${x.code} ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }

      if ("order_types" in meta) {
        let temp2 = [];
        
        meta.order_types?.map((x, ind) => {
          if(ind === 0) {
            temp2.push({
              label: "All Order Types",
              value: ""
            });
          }

          temp2.push({
            label: x.desc,
            value: x.id
          })
        });
        setOrderTypes(temp2);
      }

      if ("statuses" in meta) {
        let temp3 = [];
        
        meta.statuses?.map((x, ind) => {
          if(ind === 0) {
            temp3.push({
              label: "All Status",
              value: ""
            });
          }

          temp3.push({
            label: x === 'paid' ? 'Completed' : x,
            value: x
          })
        });
        setStatuses(temp3);
      }

      if ("tender_types" in meta) {
        let temp3 = [];
        
        meta.tender_types?.map((x, ind) => {
          if(ind === 0) {
            temp3.push({
              label: "All Tender Types",
              value: ""
            });
          }

          temp3.push({
            label: `${x.name} (${x.payment_method_type_desc})`,
            value: x.id
          })
        });
        setTenderTypes(temp3);
      }
    }
  }, [meta]);

  const onTableChange = (pagination, filters, sorter) => {
    if (orders?.total_count === 0) {
      return;
    }
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getSalesOrderList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('salesListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getSalesOrderList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('salesListQuery', pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading))
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search && 'outlet' in search) {
      let searching = {};
      let temp = [];
      if (search?.outlet?.length > 0) {
        search?.outlet?.map(x => {
          temp.push(x.value)
        });
      }
      searching = {
        order_no: search.reference,
        start_date: search.start_date ? dayjs(search.start_date).format("YYYY-MM-DD") : "",
        end_date: search.end_date ? dayjs(search.end_date).format("YYYY-MM-DD") : "",
        outlet: temp.length === 0 ? null : temp,
        order_type: search.order_type.value,
        tender_type: search.tender_type.value,
        status: search.status.value
      };

      if(search?.non_sales?.value) {
        searching["is_non_sales"] = search?.non_sales?.value
      }
      setSearchVal(searching);
      dispatch(getSalesOrderList(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('salesListQuery', 1, limit, '', '', searching, props.setLoading))
    } else {
      setSearchVal(null);
      dispatch(getSalesOrderList(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('salesListQuery', 1, limit, '', '', null, props.setLoading))
    }
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")) {
          setVisible(true);
          setRecord(record);
          setCustomActiveTab("1");
        }
      },
    };
  };

  const exportReport = async () => {
    if(orders?.total_count === 0) {
      return message.warning("No data to be exported.");
    }
    props.setLoading(true);

    await exportSoReport(selected, searchVal, selectedRowKeys).then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  const dropSelection = {
    value: selected,
    onAction: (e) => setSelected(e),
    options: filters
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Sales Orders"} drop={dropSelection} btnList={allowed([AllRoles.REPORTING.SALES], 'write') ? btnList : null} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onRow={onClickRow}
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.salesListQuery?.searchValue}
            ListData={orders?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            rowSelection={rowSelection}
            ListCol={colName}
            field1={outlets}
            field2={orderTypes}
            field3={statuses}
            field4={tenderTypes}
            pagination={{
              total: orders?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}